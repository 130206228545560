*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }
  
  .hclass{
    font-weight:bold;
  }
  
  .classbodys{
    height:87vh;
    display: flex;
    padding:10px;
    background: url("/public/images/register.jpg");
    background-size: cover;
  }

  .wrapperregister{
    width: 400px;
    background: #fff;
    height:500px !important;
    padding: 34px;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    margin-left:35%;
    margin-top: 10px;
    height:490px !important;
  }

  @media only screen and (max-width:1060px){
   .wrapperregister{
    margin-left:29%;
   }
  }

  @media only screen and (max-width:780px){
    .wrapperregister{
     margin-left:22%;
    }
   }

   @media only screen and (max-width:620px){
    .wrapperregister{
     margin-left:15%;
    }
   }

   @media only screen and (max-width:550px){
    .wrapperregister{
     margin-left:10%;
    }
   }

   @media only screen and (max-width:490px){
    .wrapperregister{
     margin-left:4%;
    }
   }


  .wrapperregister h2{
    position: relative;
    font-size: 22px;
    font-weight: 600;
    color: #333;
  }
  .wrapperregister h2::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 28px;
    border-radius: 12px;
    background: #4070f4;
  }
  .wrapperregister form{
    margin-top: 10px;
  }
  .wrapperregister form .input-box{
    height: 52px;
    margin: 12px 0;
  }
  form .input-box input{
    height: 70%;
    width: 90%;
    outline: none;
    padding: 0 15px;
    font-size: 17px;
    font-weight: 400;
    color: #333;
    border: 1.5px solid #C7BEBE;
    border-bottom-width: 2.5px;
    border-radius: 6px;
    transition: all 0.3s ease;
  }
  
  form .policy{
    display: flex;
    align-items: center;
  }
  form h3{
    color: #707070;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
  }
  .input-box.button input{
    color: #fff;
    letter-spacing: 1px;
    border: none;
    background: #4070f4;
    cursor: pointer;
  }
  .input-box.button input:hover{
    background: #0e4bf1;
  }
  form .text h3{
   color: #333;
   width: 100%;
   

  }
  form .text h3 a{
    color: #4070f4;
    text-decoration: none;
  }
  form .text h3 a:hover{
    text-decoration: underline;
  }
  