

.bodyimg{
    background-color:#005B96;
    height:auto;
    width:98.78vw;
}

@media only screen and (max-width:1050px){
    .bodyimg{
        width:100vw;
        height:auto;
    }
}

@media only screen and (max-width:900px){
    .bodyimg{
        width:100vw;
        height:auto;
    }
}

@media only screen and (max-width:655px){
    .bodyimg{
        width:100vw;
        height:auto;
    }
}

@media only screen and (max-width:550px){
    .bodyimg{
        width:100vw;
        height:auto;
    }
}

@media only screen and (max-width:450px){
    .bodyimg{
        width:100vw;
        height:auto;
    }
}

@media only screen and (max-width:400px){
    .bodyimg{
        width:100vw;
        height:auto;
    }
}

.footerimg{
    height:43px;
    filter:brightness(0) invert(1);
    margin:20px;
}

.footerspan1{
    margin-left:20px;
    color:white;
    font-size:13px;
    font-family:Arial, Helvetica, sans-serif;
}

.footerspan2{
    margin-left:5px;
    color:white;
    font-size:13px;
    font-family:Arial, Helvetica, sans-serif;
}

.sybmolspan{
    margin-left:5px;
    color:grey;
}

.line{
    width:95%;
    height:1px;
    background-color: grey;
    margin-left:17px;
    margin-top:10px;
}



.contentdiv{
    margin-left:8px;
    margin-top:15px;
    width:210px;
}

.footerhead{
    color:white;
    font-weight:bold;
    font-family:Arial, Helvetica, sans-serif;
    font-size:13px;
}

.footercontent{
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    font-size:13px;
    line-height: 0.4em;
}

.footercontent:hover{
    cursor: pointer;
    color:grey;
}


.footercontentlogo{
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    font-size:13px;
    line-height: 0.4em;
}

.logo:hover{
    color:grey;
    cursor: pointer;
}

.move{
    margin-left:15px;
}

.footerimg2{
    height:100px;
    width:85px;
    background-blend-mode: mu;
    margin:12px;
    margin-top:30px;
    object-fit: contain;
}

.footercontentcopyright{
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    font-size:11px;
    line-height: 0.4em;
    margin-left:30px;
}

.footercontentname{
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    font-size:13px;
    line-height: 0.4em;
    margin-left:10px;
}

.footercontentrights{
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    font-size:1.1vw;
    line-height: 1em;
    margin-top:10px;
}

@media screen and (max-width:500px){
    .footercontentrights{
        font-size:1.9vw;
    }
}

@media screen and (max-width:600px){
    .footercontentrights{
        font-size:2.1vw;
    }
}

@media screen and (max-width:900px){
    .footercontentrights{
        font-size:2vw;
    }
}

.moreinfo{
    text-decoration: underline;
    cursor: pointer;
}

.final{
    margin-top:30px;
    text-align: center;
}

.finalp{
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    font-size:12px;
    /* line-height: 0.4em; */
}

.spanfinal:hover{
    color:grey;
    cursor:pointer;
}