*{
    margin:0;
    padding:0;

}

.homediv{
    margin-bottom:10%;
}

.container{
    margin-top:10px;
    margin-bottom: 20px;
}

.phead{
    font-size:35px;
    font-family:'Times New Roman', Times, serif;
    text-align:center;
}

.image{
    background-image:url("/public/images/game1.webp");
    height:93vh;
    width:70vw;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
}

.displaytext{
    height:220px;
    width:380px;
    margin-left:50%;
}

@media screen and (max-width:1100px){
    .displaytext{
        height:220px;
        width:380px;
        margin-left:15%;
    }
}

@media screen and (max-width:500px){
    .displaytext{
        height:220px;
        width:380px;
        margin-left:7%;
    }
}

@media only screen and (max-width:400px){
    .displaytext{
        height:220px;
        width:380px;
        margin-left:4%;
    }
}

.spidermanhead{
    font-size:25px;
    font-family: 'Times New Roman', Times, serif;
}

.phead2{
    font-size:35px;
    font-family:'Times New Roman', Times, serif;
    text-align:center;
    margin-top:60px;
}

.phead2_2{
    font-size:35px;
    font-family:'Times New Roman', Times, serif;
    text-align:center;
}

.ptext{
    font-size:15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
}

.releasespan{
    font-weight: bolder;
}

.spidermanbutton{
    border-radius: 20px;
    height:37px;
    width:120px;
    color:white;
    background-color: #1379e0;
    border: none;
    font-weight:600;
    margin-left:10px;
}

.row{
    margin-right:0px !important;
    margin-left:0px !important;
}

.bgcolor{
    background-color:blue;
    height:120%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.box{
    height:600px;
    width:310px;
    background-color:midnightblue;
    margin-left:20px;
    margin-bottom:30px;
    border-radius:10px;
}


@media screen and (max-width:400px){
    
    .box{
        height:600px;
        width:310px;
        background-color:midnightblue;
        margin-left:40px !important;
        margin-bottom:30px;
        border-radius:10px;
        
    }
}

@media screen and (max-width:450px){
    
    .box{
        height:600px;
        width:310px;
        background-color:midnightblue;
        margin-left:50px !important;
        margin-bottom:30px;
        border-radius:10px;
        
    }
}

@media screen and (max-width:576px){
    
    .box{
        height:600px;
        width:310px;
        background-color:midnightblue;
        margin-left:85px !important;
        margin-bottom:30px;
        border-radius:10px;
        margin-left:4%;
    }
    }


@media screen and (max-width:655px){
    
.box{
    height:600px;
    width:310px;
    background-color:midnightblue;
    margin-left:15px;
    margin-bottom:30px;
    border-radius:10px;
    margin-left:24%;
}
}

@media screen and (max-width:820px){
    
    .box{
        height:600px;
        width:310px;
        background-color:midnightblue;
        margin-left:50px !important;
        margin-bottom:30px;
        border-radius:10px;
        
    }
}



@media screen and (max-width:990px){
    
    .box{
        height:600px;
        width:310px;
        background-color:midnightblue;
        margin-left:95px;
        margin-bottom:30px;
        border-radius:10px;
        
    }
}


@media screen and (min-width:1370px){
    
    .box{
        height:600px;
        width:310px;
        background-color:midnightblue;
        margin-left:30px !important;
        margin-bottom:30px;
        border-radius:10px;
        
    }
}



.boximg{
    height:390px;
    width:290px;
    border-radius:10px;
    margin-top:10px;
}

.boxhead{
    color:white;
    font-size:13px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    margin-top:8px;
}

.boxtext{
    color:white;
    font-size:11px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.boxbutton1{
    border-radius: 15px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size:11px;
    padding:5px;
    margin:3px;
    margin-top:22px;
    font-weight:bold;
    border:none;
}

.boxbutton2{
    border-radius: 15px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size:11px;
    padding:5px;
    margin:3px;
    margin-top:37px;
    font-weight:bold;
    border:none;
}

.boxbutton3{
    border-radius: 15px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size:11px;
    padding:5px;
    margin:3px;
    margin-top:37px;
    font-weight:bold;
    border:none;
}

.boxbutton4{
    border-radius: 15px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size:11px;
    padding:5px;
    margin:3px;
    margin-top:20px;
    font-weight:bold;
    border:none;
}

.image2{
    background-image:url("/public/images/game2.jpg");
    height:93vh;
    width:73vw;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
}


.displaytextuncharted{
    height:220px;
    width:380px;
    margin-left:50%;
}

@media screen and (max-width:1100px){
    .displaytextuncharted{
        height:220px;
        width:380px;
        margin-left:10%;
    }
}

@media screen and (max-width:500px){
    .displaytextuncharted{
        height:220px;
        width:380px;
        margin-left:4%;
    }
}


@media only screen and (max-width:400px){
    .displaytextuncharted{
        height:220px;
        width:380px;
        margin-left:0%;
    }
}

.unchartedbutton{
    border-radius: 20px;
    height:37px;
    width:120px;
    color:white;
    background-color: #1379e0;
    border: none;
    font-weight:600;
    margin-left:10px;
}