.card{
    margin-top:10px;
    margin-bottom: 10px;

}

.card-img-top{
    aspect-ratio:3/2;
    object-fit:contain;
    margin-top:5px;
}

.card-body{
    text-align: center;
}


#btn1{
    width:100px;
    height:40px;
    border:none;
    color:white;
    background-color:#1379e0;
    border-radius: 3px;
    transition: ease-out 1.5s;
    outline:none;
}

#btn1:hover{
    box-shadow: inset 300px 0 0 0 #161A30;
    cursor:pointer;
    color:white;
}