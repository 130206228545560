.img-display{
    height:110%;
    width:220px !important;
}


#btnadd{
    width:120px;
    height:40px;
    border:none;
    color:white;
    background-color:#1379e0;
    border-radius: 3px;
    transition: ease-out 0.5s;
    outline:none;
}

#btnadd:hover{
    box-shadow: inset 170px 0 0 0 #161A30;
    cursor:pointer;
    color:white;
}