
.navimage{
    height:50px;
    width:200px;
    margin-right: 20px;
    cursor:pointer;
}

.nav-link{
    font-size:19px;
}

.nav-links{
    color:black;
    font-size:19px;
    margin-right:10px;
    text-decoration: none;
}

li{
    margin:auto;
}

.btn-avatar{
    border:none;
    background-color: white;
    margin-right:20px;
    margin-top:10px;
}

.dropdown-items{
     font-size: 17px;
    font-family:Arial, Helvetica;
    color:black;
    text-decoration: none;
    padding: 5px;
}


.buttonli{
    text-align: center;
}
.logoutbutton{
    margin-top:5px;
    height:30px;
    width:70px;
    border-radius:3px;
    padding:2px;
    background-color: red;
    color:white;    
    border:none;
}

.spanclass{
    border-radius: 9px;
    font-size: 14px;
    background: #1379e0;
    color: #fff;
    padding: 0 5px;
    bottom:-15px;
    vertical-align:15px;
    margin-left:-8px; 
}

.margin{
    margin-left:10px;
}

.buttonstyle{
    position:relative;
    display:inline-block;
}

.buttonstyle::after{
    position: absolute;
    content:"";
    display: inline;
    height:2px;
    width:0px;
    background-color: black;
    bottom:0;
    left:0;
    transition:all 0.5s;
}

.buttonstyle:hover::after{
    width:100%;
}
